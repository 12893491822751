<template>
    <div>
        <a-modal :title="getTitle" :width="800" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
                    保存
                </a-button>
            </template>
            <a-spin :spinning="loading">
                <div class="bg-w pd40">
                    <div>
                        <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules">
                            <a-form-model-item ref="name" label="名称" help="20字以内" required :hasFeedback="true" prop="name">
                                <a-input v-model="form.name" :maxLength="20"/>
                            </a-form-model-item>
                            <a-form-model-item ref="username" label="账号" help="必须是英文" required :hasFeedback="true" prop="username">
                                <a-input v-model="form.username" :maxLength="20"/>
                            </a-form-model-item>
                            <a-form-model-item ref="mobile" label="手机号" help="用于登录" required :hasFeedback="true" prop="mobile">
                                <a-input v-model="form.mobile"/>
                            </a-form-model-item>
                            <a-form-model-item ref="password" :required="pwdRequired" label="密码" :hasFeedback="true" prop="password">
                                <a-input-password placeholder="请输入账号密码" v-model="form.password"/>
                            </a-form-model-item>

                            <a-form-model-item label="是否禁用">
                                <a-radio-group v-model="form.is_lock">
                                    <a-radio :value="0">否</a-radio>
                                    <a-radio :value="1">是</a-radio>
                                </a-radio-group>
                            </a-form-model-item>

                            <a-form-model-item required label="权限组">
                                <a-select v-model="form.group_id" >
                                    <a-select-option :value="0" :key="-1" >
                                        选择权限组
                                    </a-select-option>
                                    <a-select-option  v-for="group in groupData" :key="group.group_id" >
                                        {{ group.title }}
                                    </a-select-option>
                                </a-select>
                            </a-form-model-item>
                        </a-form-model>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        admin_id: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            loading: false,
            confirmLoading: false,
            pwdRequired:true,
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            rules: {
                name: [
                    {required: true, message: '请填写名称', trigger: 'blur'},
                ],
                username: [
                    {required: true, message: '请填写账号', trigger: 'blur'},
                ],
                mobile: [
                    {required: true, message: '手机号不能为空', trigger: 'blur'},
                    {pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur'},
                ],
                password: [
                    {validator:this.checkPassword, trigger: 'change'},
                ],
            },
            groupData:[],
            form: {
                username: '',
                mobile: '',
                password: '',
                name: '',
                is_lock: 0,
                group_id: 0,
            }
        }
    },
    computed: {
        getTitle() {
            if (this.admin_id === 0) {
                return "添加账号";
            } else {
                return "编辑账号"
            }
        }
    },
    created() {
         this.loaddata();
         if(this.admin_id){
             this.pwdRequired = false;
         }
    },
    methods: {
        loaddata() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/account/showPlatformAccount', {
                admin_id: this.admin_id,
                module:'platform',
            }).then(res => {
                if(res.detail instanceof Object) {
                    if (Object.keys(res.detail).length !== 0) {
                        this.form = res.detail;
                    }
                }
                this.groupData = res.groupData;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },

        /**
         * 取消弹窗
         */
        handleCancel() {
            this.$emit("cancel");
        },
        //校验密码
        checkPassword(rule, value, callback){
            if (this.admin_id) {
                return callback()
            }
            if(!value){
                return callback('密码不能为空');
            }
            if (value.length < 8) {
                return callback('密码不少于8位')
            }
            if (value.length > 20) {
                return callback('密码不大于20位')
            }
            // 校验是数字
            const regex1 = /^\d+$/

            if (regex1.test(value)) {
                return callback('密码不允许为纯数字')
            }
            // eslint-disable-next-line no-unused-vars
            var modes = 0;
            if(/\d/.test(value)){       //如果用户输入的密码 包含了数字
                modes++;
            }
            if(/[a-z]/.test(value)){    //如果用户输入的密码 包含了小写的a到z
                modes++;
            }
            if(/[A-Z]/.test(value)){    //如果用户输入的密码 包含了大写的A到Z
                modes++;
            }
            if(/\W/.test(value)){       //如果是非数字 字母 下划线
                modes++;
            }
            if(modes < 3){
                return callback('密码同时存在大写字母、小写字母、符号、数字任意3种组合')
            }

            return callback()
        },
        /**
         * 提交数据
         */
        onSubmit() {

            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (this.confirmLoading === true) return;
                    this.confirmLoading = true;
                    if(this.form.password === '' && !this.admin_id){
                        this.confirmLoading = false;
                        this.$message.error('请填写密码');
                        return;
                    }
                    if(this.form.group_id === 0){
                        this.confirmLoading = false;
                        this.$message.error('请选择一个权限组');
                        return;
                    }
                    this.$http.api('platform/account/savePlatformAccount', this.form).then(res => {
                        this.$message.success('操作成功', 1, () => {
                            this.confirmLoading = false;
                            this.$emit("ok");
                        })
                    }).catch(res => {
                        console.log(res);
                        this.confirmLoading = false;
                    })
                }
            });
        },

    }
}
</script>

<style>

</style>
