<template>
    <div>
        <div class="pd30">
            <div class="flex alcenter">
            </div>

            <div class="mt20">
                <div class="wxb-table-gray">
                    <a-table rowKey="id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                             :data-source="datas"
                             :loading="loading">


                        <div slot="status" slot-scope="status" class="flex alcenter center">
                            <a-tag v-if="status == 0" color="green">
                                正常
                            </a-tag>
                            <a-tag v-if="status == 1" color="red">
                                已锁定
                            </a-tag>
                            <a-tag v-if="status == 2" color="purple">
                                已过期自动解锁
                            </a-tag>
                        </div>
                        <template slot="action" slot-scope="record,index">
                            <div class="flex center">
                                <a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
                                    <a-menu slot="overlay">
                                        <a-menu-item @click="localAct(record)">
                                            <a class="menu-act" href="javascript:;">
                                                <i class="iconfont ft14 iconapplication_tab01"></i>
                                                <span class="ml10">解封</span>
                                            </a>
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </a-table>
                </div>
            </div>

            <div v-if="editStaffLoading">
                <edit-staff :staff_id="staff_id" :visible="editStaffLoading" @cancel="cancelEditStaff"
                            @ok="okEditStaff"></edit-staff>
            </div>

        </div>
    </div>
</template>

<script>
import {
    listMixin
} from '@/common/mixin/list';
import editStaff from './modal/staff/editStaff.vue';

export default {
    components: {
        editStaff,
    },
    mixins: [listMixin],
    data() {
        return {
            rules:{
                name: [
                    { required: true, message: '请填写站点名称', trigger: 'blur' },
                ],
                site_type_id:[
                    { required: true, message: '请选择分类', trigger: 'blur' },
                ],
                account_id:[
                    { required: true, message: '请绑定一个站长', trigger: 'blur' },
                ],
                site_type:[
                    { required: true, message: '请选择站点类型', trigger: 'blur' },
                ],
                site_logo:[
                    { required: true, message: '请上传站点Logo', trigger: 'change' },
                ],
                business_license:[
                    { required: true, message: '营业执照不能为空', trigger: 'change' },
                ],
                site_phone:[
                    { required: true, message: '站点联系电话不能为空', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请填写详细地址', trigger: 'blur' },
                ],
                lat: [
                    { required: true, message: '请填写经纬度', trigger: 'blur',validator:this.checkLatLng },
                ]
            },
            loading: false,
            editStaffLoading: false,
            showMiniappLoading: false,
            staff_id: 0,
            miniapp_status: 0,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            columns: [
                {title: '账号', dataIndex: 'account', align: 'center', ellipsis: true},
                {title: '类型', dataIndex: 'type_txt', align: 'center', ellipsis: true},
                {title: '错误次数', dataIndex: 'num', align: 'center', ellipsis: true},
                {title: '添加时间', dataIndex: 'create_time', align: 'center', ellipsis: true},
                {title: '到期时间', dataIndex: 'expiration_time', align: 'center', ellipsis: true},
                {title: '状态',dataIndex: 'status',align: 'center',ellipsis: true,scopedSlots: {customRender: 'status'}},
                {title: '操作', key: 'action', align: 'center', scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
        }
    },
    methods: {

        getLists() {
            if (this.loading == true) return;
            this.loading = true;
            this.$http.api('platform/account/getAccountLockList', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                console.log(res);
                this.pagination.total = res.total;
                this.datas = res.list;
                this.miniapp_status = res.miniapp_status;

                this.loading = false;
            }).catch(res => {
                this.loading = false;
            })
        },

        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },

        localAct(record) {
            if(record.status != 1){
                this.$message.error('此账号未被封禁，无法解封');
                return;
            }
            this.$confirm({
                title: '确认解封这个账号吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.api('platform/account/saveLock', {
                            id: record.id,
                        }).then(res => {
                            this.$message.success('操作成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },

        addStaffAct() {
            this.staff_id = 0;
            this.editStaffLoading = true;
        },
        editStaffAct(record) {
            this.staff_id = record.staff_id;
            this.editStaffLoading = true;
        },
        cancelEditStaff() {
            this.editStaffLoading = false;
        },
        okEditStaff() {
            this.editStaffLoading = false;
            this.getLists();
        },


        showMiniappAct() {
            this.showMiniappLoading = true;
        },
        cancelShowMiniapp() {
            this.showMiniappLoading = false;
        },
    }
}
</script>

<style>
.setting-staff-visit-miniapp {
    border-radius: 4px;
    border: 1px solid #4772FF;
    padding: 4px 8px 4px 8px;

    font-size: 12px;
    font-weight: 500;
    color: #4772FF;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.setting-staff-visit-miniapp img {
    width: 12px;
    height: 12px;
    margin-right: 4px;
}
</style>
