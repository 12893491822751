<template>
	<div>
		<a-modal :title="getTitle" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					保存
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input" style="width: 230px;" prefix="运营者" placeholder="请输入运营者姓名" v-model="form.username" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" prefix="手机号" style="width: 230px;" placeholder="请输入手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			shop_account_id:{
				type:Number,
				default:0
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				form: {
					username:'',
					mobile:'',
				}
			}
		},
		computed:{
			getTitle(){
				if(this.shop_account_id==0){
					return "添加运营账号";
				}else{
					return "编辑运营账号"
				}
			}
		},
		created() {
			// this.loaddata();
		},
		methods: {
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showShopAccount',{
					shop_account_id:this.shop_account_id,
				}).then(res=>{
					if(res.detail){
						this.form=res.detail;
					}
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.papi('platform/terminal/saveShopAccount',{
					shop_account_id:this.shop_account_id,
					username:this.form.username,
					mobile:this.form.mobile,
				}).then(res=>{
					this.$message.success('操作成功',1,()=>{
						this.confirmLoading=false;
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})

			},

		}
	}
</script>

<style>

</style>
