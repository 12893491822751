<template>
    <div>
        <div class="pd30">
            <div class="flex alcenter">
                <a-button type="primary" icon="plus" @click="addPartnerAct()">添加运营账号</a-button>

<!--                <div class="flex alcenter ml20">-->
<!--                    <div class="ft14 ftw500 cl-info">运营管理地址：</div>-->
<!--                    <div class="ft14 ftw500 cl-main">访问链接</div>-->
<!--                    <div class="ft14 ftw500 cl-theme ml8">{{ getAccountUrl }}</div>-->
<!--                </div>-->
            </div>

            <div class="mt20">
                <div class="wxb-table-gray">
                    <a-table rowKey="shop_account_id" :columns="columns" :pagination="pagination"
                             @change="handleTableChange" :data-source="datas"
                             :loading="loading">
                        <div class="flex left" slot="username" slot-scope="record">
                            {{ record.account.username }}
                        </div>
                        <div class="flex left" slot="mobile" slot-scope="record">
                            {{ record.account.mobile }}
                        </div>
                        <div class="flex left" slot="number" slot-scope="record">
                            {{ record.account.number }}
                        </div>
                        <div class="flex left" slot="type" slot-scope="record">
                            {{ record.account.type_txt }}
                        </div>

                        <div slot="status" slot-scope="record" class="flex alcenter left">
                            <a-tag v-if="record.account.status == 1" color="green">
                                正常
                            </a-tag>
                            <a-tag v-if="record.account.status == 0" color="red">
                                冻结
                            </a-tag>
                        </div>
                        <div slot="sex" slot-scope="record" class="flex alcenter left">
                            <a-tag v-if="record.account.sex == 1" color="green">
                                女
                            </a-tag>
                            <a-tag v-if="record.account.sex == 2" color="cyan">
                                男
                            </a-tag>
                            <a-tag v-if="record.account.sex == 0" color="red">
                                未知
                            </a-tag>
                        </div>
                        <div slot="bind_site" slot-scope="record">
                            <a-tag v-for="(val,index) in record.account.bind_site" color="purple" :key="index" style="float: left;">{{val}}</a-tag>
                        </div>
                        <template slot="action" slot-scope="record,index">
                            <div class="flex center">
                                <a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
                                    <a-menu slot="overlay">
                                        <a-menu-item>
                                            <a class="menu-act" href="javascript:;" @click="del(record)">
                                                <i class="iconfont ft14 icondelete"></i>
                                                <span class="ml10">解绑账号</span>
                                            </a>
                                        </a-menu-item>

                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </template>
                    </a-table>
                </div>
            </div>

            <div v-if="editPartnerLoading">
                <edit-partner :shop_account_id="shop_account_id" :visible="editPartnerLoading"
                              @cancel="cancelEditPartner" @ok="okEditPartner"></edit-partner>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listMixin
} from '@/common/mixin/list.js';
import editPartner from './modal/partner/editPartner.vue';

export default {
    components: {
        editPartner,
    },
    mixins: [listMixin],
    data() {
        return {
            loading: false,
            editPartnerLoading: false,
            shop_account_id: 0,
            pagination: {
                current: 1,
                pageSize: 10, //每页中显示10条数据
                total: 0,
            },
            columns: [
                {title: '编号', key: 'number', align: 'center', scopedSlots: {customRender: 'number'}},
                {title: '姓名', key: 'username', align: 'left', scopedSlots: {customRender: 'username'}},
                {title: '手机号', key: 'mobile', align: 'center', scopedSlots: {customRender: 'mobile'}},
                {title: '账号类型', key: 'type', align: 'left', scopedSlots: {customRender: 'type'}},
                {title: '性别', width: 120, key: 'sex', align: 'left', scopedSlots: {customRender: 'sex'}},
                {title: '状态', width: 120, key: 'status', align: 'left', scopedSlots: {customRender: 'status'}},
                {title: '所属站点',key: 'bind_site',align: 'left',scopedSlots: {customRender: 'bind_site'}},
                {title: '添加时间', dataIndex: 'add_time_format', align: 'left'},
                // {title: '操作', key: 'action', align: 'center', scopedSlots: {customRender: 'action'}}
            ],
            datas: [],
        }
    },
    computed: {
        getAccountUrl() {
            return process.env.VUE_APP_ACCOUNT_URL
        }
    },
    created() {

    },
    methods: {
        getLists() {
            if (this.loading === true) return;
            this.loading = true;
            this.$http.api('platform/account/getShopAccount', {
                limit: this.pagination.pageSize,
                page: this.pagination.current,
            }).then(res => {
                this.pagination.total = res.total;
                this.datas = res.list;
                this.loading = false;
            }).catch(res => {
                console.log(res);
                this.loading = false;
            })
        },
        handleTableChange(pagination, filters, sorter) {
            this.pagination.current = pagination.current;
            this.getLists();
        },

        del(record) {
            this.$confirm({
                title: '确认解绑这个账号吗？',
                okText: "确定",
                okType: "danger",
                cancelText: "取消",
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.papi('platform/account/delShopAccount', {
                            shop_account_id: record.shop_account_id,
                        }).then(res => {
                            this.$message.success('解绑成功');
                            this.getLists();
                        }).catch(res => {
                            console.log(res);
                        }).finally(() => {
                            resolve();
                        })
                    })
                }
            })
        },

        addPartnerAct() {
            this.shop_account_id = 0;
            this.editPartnerLoading = true;
        },
        editPartnerAct(record){
            if(record.account.type === 0){
                this.$message.error('站长账号不允许在此编辑');
                return;
            }

            this.account_id=record.account_id;
            this.editPartnerLoading=true;
        },
        cancelEditPartner() {
            this.editPartnerLoading = false;
        },
        okEditPartner() {
            this.editPartnerLoading = false;
            this.getLists();
        },
    }
}
</script>

<style>
</style>
